<template>
<v-container fluid>
  <v-row>
    <v-col md="3" class="hidden-sm-and-down">
      <v-card width="auto">
        <v-card-text>
          <div class="font-weight-bold ml-8 mb-2">
            Categories
          </div>

          <v-list nav dense>
            <v-list-item-group
              color="primary"
            >
              <v-list-item
                v-for="category in categoryItems"
                :key="category.productCategoryId"
                :to="'/category/' + category.name +'/' + category.productCategoryId"
                style="margin:0px !important; padding:0px;"
              >
                <v-list-item-icon>
                  <v-icon size="25" color="#72bd61">mdi-pill</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title v-text="category.name"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="9">
      <v-row>
        <Products></Products>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import Products from "../products/Products";
export default {
  name: "ProductCategories",
  components: {
    Products
  },
  data: () => ({
    selectedItem: null,
    categoriesList: null,
    categoryItems: null,
  }),
  created() {
    this.getProductCat();
  },
  methods: {
    getProductCat() {
      this.$http
        .get(`${process.env.VUE_APP_URL}product-categories`)
        .then((response) => {
          this.categoryItems = response.data.data;
        });
      
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
}
</style>
